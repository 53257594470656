import React, { useState } from 'react'
import { Button } from './ui/button'

const ManualValuesManager = ({ manualValues, setManualValues }) => {
    const [showForm, setShowForm] = useState(false)
    const [startValue, setStartValue] = useState('')
    const [endValue, setEndValue] = useState('')
    const [positionValue, setPositionValue] = useState('')
    const [antecedentValue, setAntecedentValue] = useState('')
    const [imageValue, setImageValue] = useState('')
    const [drawValue, setDrawValue] = useState(false)
    const [editingIndex, setEditingIndex] = useState(null)

    const resetForm = () => {
        setStartValue('')
        setEndValue('')
        setPositionValue('')
        setAntecedentValue('')
        setImageValue('')
        setDrawValue(false)
        setEditingIndex(null)
    }

    const addOrUpdateManualValue = () => {
        if (startValue && endValue && positionValue && antecedentValue && imageValue) {
            const newValue = {
                start: startValue,
                end: endValue,
                position: positionValue,
                antecedent: antecedentValue,
                image: imageValue,
                draw: drawValue,
            }

            let updatedValues
            if (editingIndex !== null) {
                updatedValues = [...manualValues]
                updatedValues[editingIndex] = newValue
            } else {
                updatedValues = [...manualValues, newValue]
            }

            setManualValues(updatedValues)
            resetForm()
            setShowForm(false)
        } else {
            alert("Veuillez remplir tous les champs avant d'ajouter ou de mettre à jour une valeur.")
        }
    }

    const startEditing = (index) => {
        const value = manualValues[index]
        setStartValue(value.start)
        setEndValue(value.end)
        setPositionValue(value.position)
        setAntecedentValue(value.antecedent)
        setImageValue(value.image)
        setDrawValue(value.draw)
        setEditingIndex(index)
        setShowForm(true)
    }

    const removeManualValue = (index) => {
        const updatedValues = manualValues.filter((_, i) => i !== index)
        setManualValues(updatedValues)
    }

    return (
        <div className="flex flex-col items-center w-full mt-6">
            <h3 className="text-md  text-gray-700 font-semibold mb-4">Ajout manuel de valeurs (optionnel)</h3>

            {!showForm && (
                <Button
                    onClick={() => setShowForm(true)}
                    className="border-gray-300 text-gray-800 hover:bg-gray-400 hover:text-white"
                    variant="outline"
                >
                    {manualValues.length === 0 ? 'Ajouter une valeur' : 'Ajouter une autre valeur'}
                </Button>
            )}

            {showForm && (
                <div className="flex flex-wrap gap-2 mb-4 w-full max-w-4xl items-end">
                    <div className="flex-1 min-w-[120px]">
                        <input
                            type="number"
                            placeholder="Début"
                            value={startValue}
                            onChange={(e) => setStartValue(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <div className="flex-1 min-w-[120px]">
                        <input
                            type="number"
                            placeholder="Fin"
                            value={endValue}
                            onChange={(e) => setEndValue(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <div className="flex-1 min-w-[120px]">
                        <input
                            type="number"
                            step="0.1"
                            min="0"
                            max="1"
                            placeholder="Position (0-1)"
                            value={positionValue}
                            onChange={(e) => setPositionValue(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <div className="flex-1 min-w-[120px]">
                        <input
                            type="text"
                            placeholder="Antécédent"
                            value={antecedentValue}
                            onChange={(e) => setAntecedentValue(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <div className="flex-1 min-w-[120px]">
                        <input
                            type="text"
                            placeholder="Image"
                            value={imageValue}
                            onChange={(e) => setImageValue(e.target.value)}
                            className="p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <div className="flex items-center self-center">
                        {' '}
                        <input
                            type="checkbox"
                            id="drawCheckbox"
                            checked={drawValue}
                            onChange={(e) => setDrawValue(e.target.checked)}
                            className="mr-2"
                        />
                        <label htmlFor="drawCheckbox">Ligne verticale</label>
                    </div>
                    <div className="flex justify-center w-full space-x-2 mt-2">
                        <Button
                            onClick={addOrUpdateManualValue}
                            className="border-gray-300 text-gray-800 hover:bg-gray-400 hover:text-white"
                            variant="outline"
                        >
                            {editingIndex !== null ? 'Mettre à jour' : 'Ajouter'}
                        </Button>
                        <Button
                            onClick={() => {
                                resetForm()
                                setShowForm(false)
                            }}
                            variant="outline"
                            className="border-gray-300 text-gray-800 hover:bg-gray-400 hover:text-white"
                        >
                            Annuler
                        </Button>
                    </div>
                </div>
            )}

            {manualValues.length > 0 && (
                <div className="w-full mt-6">
                    <h4 className="text-lg font-semibold mb-4 text-center">Valeurs ajoutées</h4>
                    <table className="w-full border-collapse">
                        <thead>
                            <tr>
                                <th className="border p-2">Début</th>
                                <th className="border p-2">Fin</th>
                                <th className="border p-2">Position</th>
                                <th className="border p-2">Antécédent</th>
                                <th className="border p-2">Image</th>
                                <th className="border p-2">Ligne verticale</th>
                                <th className="border p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {manualValues.map((value, index) => (
                                <tr key={index}>
                                    <td className="border p-2 text-center">{value.start}</td>
                                    <td className="border p-2 text-center">{value.end}</td>
                                    <td className="border p-2 text-center">{value.position}</td>
                                    <td className="border p-2 text-center">{value.antecedent}</td>
                                    <td className="border p-2 text-center">{value.image}</td>
                                    <td className="border p-2 text-center">{value.draw ? 'Oui' : 'Non'}</td>
                                    <td className="border p-2 text-center">
                                        <Button
                                            onClick={() => startEditing(index)}
                                            variant="outline"
                                            size="sm"
                                            className="border-gray-300 text-gray-800 hover:bg-gray-400 hover:text-white mr-2"
                                        >
                                            Éditer
                                        </Button>
                                        <Button
                                            onClick={() => removeManualValue(index)}
                                            variant="destructive"
                                            size="sm"
                                            className="border-gray-300 text-gray-800 hover:bg-gray-400 hover:text-white"
                                        >
                                            Supprimer
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default ManualValuesManager
