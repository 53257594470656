import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { useWindowWidth } from '@wojtekmaj/react-hooks'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`

function PDFViewer({ pdfUrl }) {
    const width = useWindowWidth()

    return (
        <div className="pdf-container" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Document
                file={pdfUrl}
                onLoadError={(error) => console.error('Error while loading document!', error.message)}
                loading={<div>Chargement du PDF...</div>}
            >
                <Page pageNumber={1} width={width * 0.75} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>
        </div>
    )
}

export default PDFViewer
