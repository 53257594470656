import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { MathJax, MathJaxContext } from 'better-react-mathjax'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from './components/ui/select'
import { Button } from './components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from './components/ui/react-tabs'
import { Card, CardContent } from './components/ui/card'
import PDFViewer from './components/PDFViewer'
import { ChevronDown } from 'lucide-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import ManualValuesManager from './components/ManualValuesManager'
import AdvancedOptions from './components/AdvancedOptions'
import { useMediaQuery } from '@mui/material'

const API_URL = '/generate_table'

function VariationTable() {
    const [func, setFunc] = useState('(x^2-2*x-3)/(x-5)')
    const [lowerBound, setLowerBound] = useState('-oo')
    const [upperBound, setUpperBound] = useState('oo')
    const [latexOutput, setLatexOutput] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [domain, setDomain] = useState('')
    const [derivative, setDerivative] = useState('')
    const [isDomainLoading, setIsDomainLoading] = useState(false)
    const [isDerivativeLoading, setIsDerivativeLoading] = useState(false)
    const [displayOption, setDisplayOption] = useState('all')
    const [expressionDisplay, setExpressionDisplay] = useState('full')
    const [notification, setNotification] = useState(null)
    const [mathJaxReady, setMathJaxReady] = useState(false)
    const [pdfUrl, setPdfUrl] = useState(null)
    const [pngUrl, setPngUrl] = useState(null)
    const [manualValues, setManualValues] = useState([])
    const [showLimits, setShowLimits] = useState(true)
    const [showFunctionNotation, setShowFunctionNotation] = useState(false)
    const isMobile = useMediaQuery('(max-width:640px)')

    useEffect(() => {
        if (domain && derivative && !mathJaxReady) {
            setMathJaxReady(true)
            if (window.MathJax) {
                window.MathJax.typeset()
            }
        }
    }, [domain, derivative, mathJaxReady])

    const calculateDerivative = useCallback(async () => {
        setIsDerivativeLoading(true)
        try {
            const response = await axios.post(`${API_URL}/derivative`, {
                function: func,
            })
            setDerivative(response.data.derivative)
        } catch (err) {
            console.error('Error calculating derivative:', err)
            setDerivative('')
        }
        setIsDerivativeLoading(false)
    }, [func])

    const calculateDomain = useCallback(async () => {
        setIsDomainLoading(true)
        try {
            const response = await axios.post(`${API_URL}/domain`, {
                function: func,
            })
            setDomain(response.data.domain)
        } catch (err) {
            console.error('Error calculating domain:', err)
            setDomain('')
        }
        setIsDomainLoading(false)
    }, [func])

    useEffect(() => {
        if (func) {
            const timer = setTimeout(() => {
                calculateDerivative()
                calculateDomain()
            }, 500)
            return () => clearTimeout(timer)
        }
    }, [func, calculateDerivative, calculateDomain])

    const copyToClipboard = () => {
        navigator.clipboard.writeText(latexOutput).then(
            () => {
                setNotification('Code LaTeX copié dans le presse-papier !')
                setTimeout(() => setNotification(null), 2000)
            },
            (err) => {
                console.error('Could not copy text: ', err)
                setNotification('Failed to copy LaTeX')
                setTimeout(() => setNotification(null), 2000)
            }
        )
    }

    const editInTeXlive = () => {
        const fullLatexCode = `\\documentclass[border=20pt]{standalone}
\\usepackage{mlmodern}
\\usepackage[T1]{fontenc}
\\usepackage{tikz}
\\usepackage{tkz-tab}
\\begin{document}
${latexOutput}
\\end{document}`

        const encodedCode = encodeURIComponent(fullLatexCode)
        const texliveUrl = `https://texlive.net/run?${encodedCode}`
        window.open(texliveUrl, '_blank')
    }

    const generateContent = useCallback(async () => {
        setLoading(true)
        setError('')
        try {
            const latexResponse = await axios.post(API_URL, {
                function: func,
                lowerBound: lowerBound,
                upperBound: upperBound,
                displayOption: displayOption,
                expressionDisplay: expressionDisplay,
                manualValues: manualValues,
                showLimits: showLimits,
                showFunctionNotation: showFunctionNotation,
            })

            if (latexResponse.data.error) {
                setError(latexResponse.data.error)
                setLatexOutput('')
                setPngUrl(null)
                setPdfUrl(null)
                return
            }

            setLatexOutput(latexResponse.data.latex)

            const pngResponse = await axios.post(
                `${API_URL}/generate_pdf`,
                { latex: latexResponse.data.latex },
                { responseType: 'arraybuffer' }
            )

            const pngBlob = new Blob([pngResponse.data], { type: 'image/png' })
            const pngUrl = URL.createObjectURL(pngBlob)
            setPngUrl(pngUrl)

            const pdfResponse = await axios.post(
                `${API_URL}/generate_pdf`,
                { latex: latexResponse.data.latex },
                { responseType: 'arraybuffer' }
            )

            const pdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' })
            const pdfUrl = URL.createObjectURL(pdfBlob)
            setPdfUrl(pdfUrl)
        } catch (err) {
            console.error('Error:', err)
            if (err.response && err.response.data && err.response.data.error) {
                setError(err.response.data.error)
            } else {
                setError(`Erreur: ${err.message}`)
            }
            setLatexOutput('')
            setPngUrl(null)
            setPdfUrl(null)
        } finally {
            setLoading(false)
        }
    }, [func, lowerBound, upperBound, displayOption, expressionDisplay, manualValues, showLimits, showFunctionNotation])

    return (
        <MathJaxContext>
            <div className="min-h-screen bg-[#eceef6]">
                <div className="bg-[#3f51b5] text-white py-4 px-6 shadow-md relative flex justify-between items-center">
                    <a
                        href="https://apps.edulatex.xyz"
                        rel="noopener noreferrer"
                        className="text-white hover:text-white transition-colors duration-200"
                    >
                        <FontAwesomeIcon icon={faHome} className="text-lg transform scale-95" />
                    </a>
                    <h1 className="text-xl font-bold text-center absolute left-1/2 transform -translate-x-1/2">
                        Générateur de tableaux de variation
                    </h1>
                    <div className="w-6"></div>
                </div>
                <div className="container mx-auto p-4 mt-6">
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault()
                                generateContent()
                            }}
                            className="mb-6 space-y-6"
                        >
                            <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
                                <div className="w-full md:w-1/2">
                                    <label
                                        htmlFor="function"
                                        className="block mb-2 text-lg font-semibold text-gray-700 text-center"
                                    >
                                        Expression de {mathJaxReady && <MathJax dynamic inline>{`\\(f(x)\\)`}</MathJax>}
                                    </label>
                                    <input
                                        type="text"
                                        id="function"
                                        value={func}
                                        onChange={(e) => setFunc(e.target.value)}
                                        className="w-full p-2 border bg-[#ECEEF6] rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center"
                                        placeholder="e.g., (x^2-2*x-3)/(x-5)"
                                    />
                                </div>
                                <div className="w-full md:w-1/4 flex flex-col items-center">
                                    <label
                                        htmlFor="lowerBound"
                                        className="block mb-2 text-md font-medium text-gray-700 text-center"
                                    >
                                        Borne inférieure
                                    </label>
                                    <input
                                        type="text"
                                        id="lowerBound"
                                        value={lowerBound}
                                        onChange={(e) => setLowerBound(e.target.value)}
                                        className="w-full p-2 border bg-[#ECEEF6] rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center"
                                        placeholder="-oo"
                                    />
                                </div>
                                <div className="w-full md:w-1/4 flex flex-col items-center">
                                    <label
                                        htmlFor="upperBound"
                                        className="block mb-2 text-md font-medium text-gray-700 text-center"
                                    >
                                        Borne supérieure
                                    </label>
                                    <input
                                        type="text"
                                        id="upperBound"
                                        value={upperBound}
                                        onChange={(e) => setUpperBound(e.target.value)}
                                        className="w-full p-2 border bg-[#ECEEF6] rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center"
                                        placeholder="oo"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row justify-between items-stretch space-y-4 md:space-y-0 md:space-x-4">
                                <div className="w-full md:w-1/2">
                                    <h2 className="text-lg font-semibold mb-2 text-gray-700 text-center">
                                        Domaine de définition
                                    </h2>
                                    <div className="p-4 rounded-md border border-gray-200 min-h-[6rem] flex items-center justify-center overflow-x-auto">
                                        <div className="max-w-full break-words">
                                            {!isDomainLoading && domain && (
                                                <MathJax dynamic>{`\\(\\displaystyle ${domain}\\)`}</MathJax>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2">
                                    <h2 className="text-lg font-semibold mb-2 text-gray-700 text-center">
                                        Fonction dérivée
                                    </h2>
                                    <div className="p-4 rounded-md border border-gray-200 min-h-[6rem] flex items-center justify-center overflow-x-auto">
                                        <div className="max-w-full break-words">
                                            {!isDerivativeLoading && derivative && (
                                                <MathJax
                                                    dynamic
                                                >{`\\(\\displaystyle f'(x) = ${derivative}\\)`}</MathJax>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AdvancedOptions>
                                <div className="space-y-6">
                                    <div className="flex flex-col items-center">
                                        <label className="block mb-2 font-semibold text-gray-700 text-center">
                                            Afficher les expressions de{' '}
                                            {mathJaxReady && <MathJax dynamic inline>{`\\(f(x)\\)`}</MathJax>} et de{' '}
                                            {mathJaxReady && <MathJax dynamic inline>{`\\(f'(x)\\)`}</MathJax>}
                                        </label>
                                        <div className="flex justify-center space-x-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    className="form-radio text-indigo-800"
                                                    name="expressionDisplay"
                                                    value="full"
                                                    checked={expressionDisplay === 'full'}
                                                    onChange={(e) => setExpressionDisplay(e.target.value)}
                                                />
                                                <span className="ml-2">Oui</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    className="form-radio text-indigo-800"
                                                    name="expressionDisplay"
                                                    value="simple"
                                                    checked={expressionDisplay === 'simple'}
                                                    onChange={(e) => setExpressionDisplay(e.target.value)}
                                                />
                                                <span className="ml-2">Non</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex flex-col items-center w-full">
                                        <Select value={displayOption} onValueChange={setDisplayOption}>
                                            <SelectTrigger
                                                className={`${
                                                    isMobile ? 'w-full' : 'w-6/12'
                                                } bg-[#eceef6] border-gray-300 text-md font-semibold text-gray-700 relative flex items-center justify-center`}
                                            >
                                                <div className="text-center w-full pointer-events-none">
                                                    <SelectValue
                                                        placeholder="Select display option"
                                                        className="appearance-none"
                                                    />
                                                </div>
                                                <span className="absolute right-3 pointer-events-auto">
                                                    <ChevronDown size={20} />
                                                </span>
                                            </SelectTrigger>

                                            <SelectContent className="bg-[#eceef6]">
                                                <SelectItem value="all" className="text-md text-gray-700">
                                                    {isMobile ? 'Toutes les lignes' : 'Afficher toutes les lignes'}
                                                </SelectItem>
                                                <SelectItem value="variations" className="text-md text-gray-700">
                                                    {isMobile
                                                        ? 'Variations f'
                                                        : 'Afficher uniquement les variations de f'}
                                                </SelectItem>
                                                <SelectItem
                                                    value="derivative_variations"
                                                    className="text-md text-gray-700"
                                                >
                                                    {isMobile
                                                        ? "Signe f' et variations f"
                                                        : "Afficher le signe de f'(x) et les variations de f"}
                                                </SelectItem>
                                                <SelectItem value="derivative" className="text-md text-gray-700">
                                                    {isMobile ? "Signe f'" : "Afficher uniquement le signe de f'(x)"}
                                                </SelectItem>
                                                <SelectItem
                                                    value="derivative_intermediates"
                                                    className="text-md text-gray-700"
                                                >
                                                    {isMobile
                                                        ? "Signe f' et facteurs"
                                                        : "Afficher le signe de f'(x) et celui de chacun de ses facteurs"}
                                                </SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <ManualValuesManager
                                        manualValues={manualValues}
                                        setManualValues={setManualValues}
                                    />
                                    <div className="flex flex-col items-center">
                                        <label className="block mb-2 font-semibold text-gray-700 text-center">
                                            Afficher les limites
                                        </label>
                                        <div className="flex justify-center space-x-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    className="form-radio text-indigo-800"
                                                    name="showLimits"
                                                    value="true"
                                                    checked={showLimits}
                                                    onChange={() => setShowLimits(true)}
                                                />
                                                <span className="ml-2">Oui</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    className="form-radio text-indigo-800"
                                                    name="showLimits"
                                                    value="false"
                                                    checked={!showLimits}
                                                    onChange={() => setShowLimits(false)}
                                                />
                                                <span className="ml-2">Non</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex flex-col items-center">
                                        <label className="block mb-2 font-semibold text-gray-700 text-center">
                                            Utiliser la notation{' '}
                                            {mathJaxReady && <MathJax dynamic inline>{`\\(f(x)\\)`}</MathJax>} pour les
                                            images
                                        </label>
                                        <div className="flex justify-center space-x-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    className="form-radio text-indigo-800"
                                                    name="showFunctionNotation"
                                                    value="true"
                                                    checked={showFunctionNotation}
                                                    onChange={() => setShowFunctionNotation(true)}
                                                />
                                                <span className="ml-2">Oui</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    className="form-radio text-indigo-800"
                                                    name="showFunctionNotation"
                                                    value="false"
                                                    checked={!showFunctionNotation}
                                                    onChange={() => setShowFunctionNotation(false)}
                                                />
                                                <span className="ml-2">Non</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </AdvancedOptions>

                            <div className="flex justify-center w-full">
                                <Button
                                    type="submit"
                                    className="w-full bg-[#3f51b5] hover:bg-[#303f9f] text-md text-white"
                                    disabled={loading}
                                >
                                    {loading ? 'Génération en cours...' : 'Générer et visualiser le tableau'}
                                </Button>
                            </div>
                        </form>

                        {error && <div className="text-red-500 mb-4 text-center">{error}</div>}

                        {(latexOutput || pngUrl || pdfUrl) && (
                            <Tabs defaultValue="image" className="mt-8">
                                <TabsList className="grid w-full grid-cols-3">
                                    <TabsTrigger value="image">Image</TabsTrigger>
                                    <TabsTrigger value="pdf">PDF</TabsTrigger>
                                    <TabsTrigger value="latex">Code LaTeX</TabsTrigger>
                                </TabsList>
                                <TabsContent value="image">
                                    <Card>
                                        <CardContent className="pt-6 w-full max-w-[95vw] mx-auto">
                                            {pngUrl ? (
                                                <PDFViewer key={pngUrl} pdfUrl={pngUrl} />
                                            ) : (
                                                <p className="text-center text-gray-500"></p>
                                            )}
                                        </CardContent>
                                    </Card>
                                </TabsContent>
                                <TabsContent value="pdf">
                                    <Card>
                                        <CardContent className="pt-6">
                                            {pdfUrl ? (
                                                <iframe
                                                    src={`${pdfUrl}#view=Fit&toolbar=0&navpanes=0`}
                                                    style={{ width: '100%', height: '500px', border: 'none' }}
                                                    title="PDF Viewer"
                                                />
                                            ) : (
                                                <p className="text-center text-gray-500"></p>
                                            )}
                                        </CardContent>
                                    </Card>
                                </TabsContent>
                                <TabsContent value="latex">
                                    <Card>
                                        <CardContent className="pt-6">
                                            <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto text-sm">
                                                {latexOutput}
                                            </pre>
                                            <div className="mt-4 flex justify-end space-x-2">
                                                <Button onClick={copyToClipboard} variant="outline">
                                                    Copier dans le presse-papier
                                                </Button>
                                                <Button onClick={editInTeXlive} variant="outline">
                                                    Éditer sur TeXlive.net
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </TabsContent>
                            </Tabs>
                        )}
                    </div>
                </div>
                {notification && (
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-200 text-green-900 py-2 px-4 rounded-md shadow-lg notification">
                        {notification}
                    </div>
                )}
            </div>
        </MathJaxContext>
    )
}

export default VariationTable
