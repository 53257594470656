import React, { useState } from 'react'
import { Button } from './ui/button'
import { ChevronDown, ChevronUp } from 'lucide-react'

const AdvancedOptions = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="mt-6 border-gray-300 bg-[#eceef6] rounded-md">
            <Button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                variant="ghost"
                className="w-full flex text-md text-gray-700 border-gray-300 font-semibold justify-center items-center p-4 relative"
            >
                <div className="pointer-events-none w-full text-center">
                    {' '}
                    <span>Options d'affichage</span>
                </div>
                <span className="absolute right-4 pointer-events-auto">
                    {' '}
                    {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </span>
            </Button>
            {isOpen && <div className="p-4 border-t border-gray-300">{children}</div>}
        </div>
    )
}

export default AdvancedOptions
